import { useEffect, useState } from 'react'
import logo from '../assets/logo.png'
import { WalletMultiButton } from '@solana/wallet-adapter-react-ui'
import { useWallet } from '@solana/wallet-adapter-react'
import { isUserSubscribed } from '../backend/subscriptions'
import { NetworkTypes1 } from '../backend/utils'
import  useStore  from '../backend/store'


export default function Landing({
  ischecked2,
  setIsChecked2,
}: {
  ischecked2: boolean
  setIsChecked2: (_: boolean) => void
}) {
  const wallet = useWallet()
  const network = useStore(s => s.network)
  const setNetwork = useStore(s => s.setNetwork)
  const [isSubscribed, setIsSubscribed] = useState(false)
  const isAdmin = useStore(s => s.isAdmin)
  const setIsAdmin = useStore(s => s.setIsAdmin)

  const isSubscriber = async () => {
    try {
      if (wallet.publicKey) {
        if (await isUserSubscribed(network, wallet.publicKey.toString(), isAdmin)) {
          setIsChecked2(true)
          setIsSubscribed(true)
        } else {
          setIsChecked2(true)
        }
      } else {
        setIsChecked2(false)
        setIsSubscribed(false)
      }
    } catch (err) {
      console.log(err)
    }
  }
  useEffect(() => {
    isSubscriber()
  }, [wallet])

  const verifyAdmin = () => {
    const result = prompt('Enter the password');
    if(result === 'CPA6pEtLUXMFYM4EX259yYR3FShHVQZFEtgnFdXChPbL') {
      setIsAdmin(true)
      setIsSubscribed(true)
    }else {
      alert('Wrong Password')
    }
  }

  return (
    <>
      <div
        className="nowalletmodal2"
        style={{ display: ischecked2 && isSubscribed ? 'none' : 'flex' }}
      >
        <div className="submodal2">
          <a href="https://solwerx.com/">
            <img src={logo} className="logo" alt="SolWerx" height="50px" />
          </a>
          <div className="inputsc">
            <h2>RESTRICTED AREA</h2>
          </div>

          {ischecked2 && !isSubscribed ? (
            <div>
              <p style={{ color: 'white' }}>
                You currently don't have any subscription or your subscription
                has Expired,{' '}
              </p>
              <br></br>
              <div style={{display: 'flex', flexWrap: 'wrap', justifyContent: 'center'}}>
                <button
                  className="modalswitcher"
                  onClick={() => window.open('https://subscribe.solwerx.com/')}
                >
                  Get a Subscription
                </button>
                <button
                  className="modalswitcher"
                  onClick={verifyAdmin}
                >
                  Admin
                </button>
              </div>
            </div>
          ) : null}

          <fieldset className="inputsc">
            <legend>Select Network:</legend>

            <div>
              <input
                type="radio"
                id="huey"
                value={NetworkTypes1.MAINNET}
                onChange={(e) => {
                  setNetwork(NetworkTypes1.MAINNET)
                }}
                checked={network === NetworkTypes1.MAINNET}
              />

              <label htmlFor="huey">mainnet</label>
            </div>

            <div>
              <input
                type="radio"
                id="dewey"
                value={NetworkTypes1.DEVNET}
                checked={network === NetworkTypes1.DEVNET}
                onChange={(e) => {
                  setNetwork(NetworkTypes1.DEVNET)
                }}
              />
              <label htmlFor="dewey">devnet</label>
            </div>
          </fieldset>

          <div>
            <WalletMultiButton className="modalswitcherLanding" />
          </div>
        </div>
      </div>
    </>
  )
}
