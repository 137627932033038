import { bs58 } from '@project-serum/anchor/dist/cjs/utils/bytes'
import { Keypair } from '@solana/web3.js'
import { isUserSubscribed } from '../backend/subscriptions'

export enum WStatus {
  Valid = 'Valid',
  Invalid = 'Invalid',
  NotSubscribed = 'NotSubscribed',
}

export const validateWallet = async (
  pubkey: string,
  seckey: string,
  network: string,
  isAdmin: boolean
) => {

  try {
    let _secKey = new Uint8Array([])
    if (seckey[0] !== '[') {
      _secKey = bs58.decode(seckey.trim())
    } else {
      _secKey = Uint8Array.from(JSON.parse(seckey.trim()))
    }
    const keypair = Keypair.fromSecretKey(_secKey)
    if (pubkey.trim() !== keypair.publicKey.toString()) {
      alert('Invalid Userkey or Secretkey')
      return WStatus.Invalid
    }

    const isSubcribed = await isUserSubscribed(
      network,
      keypair.publicKey.toString(),
      isAdmin
    )
    if (isSubcribed) return WStatus.Valid
    return WStatus.NotSubscribed
  } catch (err) {
    console.log(err)
    alert('Invalid Userkey or Secretkey')
    return WStatus.Invalid
  }
}

