import React, { useEffect, useRef, useState, useMemo } from 'react'
import './App.css'
import * as fetchdata from './api/solana-automated'
import { Maincontainer, Card, TopNav, CardChild } from './Components/styling'
import Navigation from './Components/Navigation/Navigation'
import SideBarNavigation from './Components/SideBarNaviation/SideBarNavigation'

import { Contact } from './Components/Contact/Contact'
import { filterRecipientAddrress } from './api/solana-api'
import Button from '@mui/material/Button'

import { purple } from '@mui/material/colors'
import useStore from './backend/store'
import uiStore from './backend/uiStore'
import Page1 from './Components/Page1/Page1'
import tokenStore from './backend/token.store'
import Page2 from './Components/Page2/Page2'
import Page3 from './Components/Page3/Page3'
import SignIn from './Components/SignIn'
import Landing from './Components/landing'
import { Page4 } from './Components/Page4/index'

import * as solananft from './api/solananft'
import { sleep } from './Components/Utils/download'
import { clusterApiUrl } from '@solana/web3.js'
import { WalletAdapterNetwork } from '@solana/wallet-adapter-base'
import {
  GlowWalletAdapter,
  LedgerWalletAdapter,
  PhantomWalletAdapter,
  SlopeWalletAdapter,
  SolflareWalletAdapter,
  SolletExtensionWalletAdapter,
  SolletWalletAdapter,
  TorusWalletAdapter,
} from '@solana/wallet-adapter-wallets'
import {
  ConnectionProvider,
  WalletProvider,
} from '@solana/wallet-adapter-react'
import { WalletModalProvider } from '@solana/wallet-adapter-react-ui'
import { NetworkTypes1 } from './backend/utils'
require('@solana/wallet-adapter-react-ui/styles.css')

interface transferedData {
  tx: string
  destination: string
  amount: number
  flag: boolean
  time: string
}

interface transferedDataNFT {
  tx: string
  destination: string
  mint: string
  flag: boolean
  time: string
}

function App() {
  const cancelRef = useRef<boolean>(false)
  const publicKey = useStore((state) => state.publicKey)
  const privateKey = useStore((s) => s.privateKey)
  const [ischecked, setIsChecked] = React.useState(false)
  const [ischecked2, setIsChecked2] = React.useState(false)
  const [toggleModal, setToggleModal] = React.useState(false)
  const step = uiStore((s) => s.step)
  const setStep = uiStore((s) => s.setStep)
  const setCompletedTransfers = tokenStore((s) => s.setCompletedTransfers)
  const setTotalTransfers = tokenStore((s) => s.setTotalTransfers)
  const cancelled = tokenStore((s) => s.cancelled)

  const tokenAddress = useStore((state) => state.tokenAddr)
  const tokenDecimals = useStore((s) => s.tokenDecimals)
  const recipientData = useStore((state) => state.recipientData)
  const [showinput, setShowInput] = useState(true)
  const [displaypayment, setdsiplaypayment] = useState(false)
  const [iscancelled, setIscancelled] = useState(false)
  const [lastSignature, setLastSignature] = useState('')
  const [click, setClick] = useState(false)
  const setFailedTransfers = tokenStore((s) => s.setFailedTransfers)
  const network = useStore(s => s.network)
  const darkMode = uiStore((state) => state.darkMode)
  const setDarkMode = uiStore((state) => state.setDarkMode)
  const contactToTeam = uiStore((state) => state.contactToTeam)
  const setContactToTeam = uiStore((state) => state.setContactToTeam)
  const [lvlone, setlvlone] = useState(true)
  const [lvltwo, setlvltwo] = useState(false)
  const [lvlthree, setthree] = useState(false)

  const transactionHashListSOL = useStore(
    (state) => state.transactionHashListSOL
  )
  const settransactionHashListSOL = useStore(
    (state) => state.settransactionHashListSOL
  )

  const transactionHashListSPL = useStore(
    (state) => state.transactionHashListSPL
  )
  const settransactionHashListSPL = useStore(
    (state) => state.settransactionHashListSPL
  )

  const transactionHashListNFT = useStore(
    (state) => state.transactionHashListNFT
  )
  const settransactionHashListNFT = useStore(
    (state) => state.settransactionHashListNFT
  )
  const solNetwork = WalletAdapterNetwork.Mainnet
  const endpoint = useMemo(() => clusterApiUrl(solNetwork), [solNetwork])
  // initialise all the wallets you want to use
  const wallets = useMemo(
    () => [
      new PhantomWalletAdapter(),
      new GlowWalletAdapter(),
      new SlopeWalletAdapter(),
      new SolflareWalletAdapter(),
      new TorusWalletAdapter(),
      new LedgerWalletAdapter(),
      new SolletExtensionWalletAdapter(),
      new SolletWalletAdapter(),
    ],
    [solNetwork]
  )

  useEffect(() => {
    if (step === 2 || step === 3) {
      setdsiplaypayment(true)
    }
  }, [step])

  useEffect(() => {
    if (cancelled === true) {
      cancelRef.current = true
    } else {
      cancelRef.current = false
    }
  }, [cancelled])

  const splTokenTransfer1 = async () => {
    let _len: any
    try {
      if (network === NetworkTypes1.DEVNET) {
        fetchdata.setDevnet()
      } else {
        fetchdata.setMainnet()
      }

      const recipientData2 = recipientData.trim()
      let info_object: { destination_array: string[]; money_array: string[] } =
        filterRecipientAddrress(recipientData2)
      let len = info_object.money_array.length

      setTotalTransfers(len)

      setStep(2)

      let err_array = []

      _len = info_object.money_array.length
      for (let i = 0; i < info_object.money_array.length; i++) {
        if (cancelRef.current) {
          setCompletedTransfers(i - 1)
          setIscancelled(true)

          break
        } else {
          setCompletedTransfers(i)
          const status = await splTransfer1(
            info_object.destination_array[i],
            info_object.money_array[i],
            tokenDecimals,
            tokenAddress,
            privateKey
          )

          if (status.value === false) {
            err_array.push(
              `${info_object.destination_array[i]},${info_object.money_array[i]}`
            )

            let prevState: transferedData[] = transactionHashListSPL
            prevState.push({
              tx: status.sign,
              destination: info_object.destination_array[i],
              amount: Number(info_object.money_array[i]),
              flag: false,
              time: `${new Date()}`,
            })

            settransactionHashListSPL([...prevState])

            setFailedTransfers([...err_array])
          } else {
            let prevState: transferedData[] = transactionHashListSPL
            prevState.push({
              tx: status.sign,
              destination: info_object.destination_array[i],
              amount: Number(info_object.money_array[i]),
              flag: true,
              time: `${new Date()}`,
            })

            settransactionHashListSPL([...prevState])
            setLastSignature(status.sign)
          }

          await sleep(3000)
        }
      }

      setCompletedTransfers(len)
    } catch (err) {
      console.log(err)
      alert(err)
      setIscancelled(true)
      setCompletedTransfers(_len)
    }
  }

  const splTransfer1 = async (
    dest1: string,
    amount1: string,
    tokenDecimals: string,
    tokenAddress: string,
    privateKey: string
  ) => {
    let val = await fetchdata.spltransferToken(
      dest1,
      amount1,
      tokenDecimals,
      tokenAddress,
      privateKey
    )
    return val
  }

  const transfersolana = async () => {
    let _len: any
    try {
      if (network === NetworkTypes1.DEVNET) {
        fetchdata.setDevnet()
      } else {
        fetchdata.setMainnet()
      }

      const recipientData2 = recipientData.trim()
      let info_object = filterRecipientAddrress(recipientData2)
      let len = info_object.money_array.length
      setTotalTransfers(len)

      setStep(2)

      _len = info_object.money_array.length

      let err_array = []
      for (let i = 0; i < info_object.money_array.length; i++) {
        if (cancelRef.current) {
          setCompletedTransfers(i - 1)
          setIscancelled(true)

          break
        } else {
          setCompletedTransfers(i)
          const status = await fetchdata.nativesolanatransfer(
            info_object.money_array[i],
            info_object.destination_array[i],
            privateKey
          )
          if (status.value === false) {
            err_array.push(
              `${info_object.destination_array[i]},${info_object.money_array[i]}`
            )

            let prevState: transferedData[] = transactionHashListSOL
            prevState.push({
              tx: status.sign,
              destination: info_object.destination_array[i],
              amount: Number(info_object.money_array[i]),
              flag: false,
              time: `${new Date()}`,
            })
            settransactionHashListSOL([...prevState])

            setFailedTransfers([...err_array])
          } else {
            let prevState: transferedData[] = transactionHashListSOL
            prevState.push({
              tx: status.sign,
              destination: info_object.destination_array[i],
              amount: Number(info_object.money_array[i]),
              flag: true,
              time: `${new Date()}`,
            })

            settransactionHashListSOL([...prevState])

            setLastSignature(status.sign)
          }

          await sleep(3000)
        }
      }

      setCompletedTransfers(len)
    } catch (err) {
      alert(err)
      setIscancelled(true)
      setCompletedTransfers(_len)
    }
  }

  const nftTransfer1 = async () => {
    let _len: any
    setCompletedTransfers(0)

    if (network === NetworkTypes1.DEVNET) {
      fetchdata.setDevnet()
    } else {
      fetchdata.setMainnet()
    }

    try {
      const trim_receipient_data = recipientData.trim()
      let infoObject = solananft.filterRecipientAddrress(trim_receipient_data)
      let len = infoObject.token_array.length
      setTotalTransfers(len)

      setStep(2)

      _len = infoObject.token_array.length

      let err_array = []
      for (let i = 0; i < infoObject.token_array.length; i++) {
        if (cancelRef.current) {
          setCompletedTransfers(i - 1)

          break
        } else {
          setCompletedTransfers(i)
          let status: any = await fetchdata.nfttransfer(
            infoObject.destination_array[i],
            infoObject.token_array[i],
            privateKey
          )

          if (status.value === false) {
            err_array.push(
              `${infoObject.destination_array[i]},${infoObject.token_array[i]}`
            )

            let prevState: transferedDataNFT[] = transactionHashListNFT
            prevState.push({
              tx: status.sign,
              destination: infoObject.destination_array[i],
              mint: infoObject.token_array[i],
              flag: false,
              time: `${new Date()}`,
            })
            settransactionHashListNFT([...prevState])

            setFailedTransfers([...err_array])
          } else {
            let prevState: transferedDataNFT[] = transactionHashListNFT
            prevState.push({
              tx: status.sign,
              destination: infoObject.destination_array[i],
              mint: infoObject.token_array[i],
              flag: true,
              time: `${new Date()}`,
            })

            settransactionHashListNFT([...prevState])
            setLastSignature(status.sign)
          }

          await sleep(3000)
        }
      }

      setCompletedTransfers(len)
    } catch (err) {
      alert(err)
      setCompletedTransfers(_len)
    }
  }

  return (
    <ConnectionProvider endpoint={endpoint}>
      <WalletProvider wallets={wallets}>
        <WalletModalProvider>
          <div className="App">
            <main>
              <Navigation
                setcontacttoteam={setContactToTeam}
                setIsChecked={setIsChecked}
                ischecked={ischecked}
                toggleModal={toggleModal}
                setToggleModal={setToggleModal}
                open={false}
                click={click}
                setClick={setClick}
              />
              <Maincontainer
                mode={darkMode}
                currNetwork={network}
                darkMode={darkMode}
              >
                <SideBarNavigation
                  mode={darkMode}
                  currNetwork={network}
                  setMode={setDarkMode}
                />

                <div
                  style={{ display: step === 4 ? 'none' : 'block' }}
                  className="cardconatiner"
                >
                  <TopNav
                    mode={darkMode}
                    currNetwork={network}
                    lvlone={lvlone}
                    lvltwo={lvltwo}
                    lvlthree={lvlthree}
                  >
                    <div className="progressbtn">
                      <div id="levelone"></div>
                      <div className="rectangle"></div>
                      <div id="leveltwo"></div>
                      <div className="rectangle"></div>
                      <div id="levelthree"></div>
                    </div>
                    <div id="progresstittle">
                      <p className="subtittle">Prepare</p>
                      <p className="subtittle">Processing</p>
                      <p className="subtittle">Done</p>
                    </div>
                  </TopNav>
                  <Card mode={darkMode} currNetwork={network}>
                    <Button
                      sx={{
                        backgroundColor: '#b64ef9',
                        color: 'white',
                        padding: '10px 50px',
                        marginTop: '20px',
                        '&:hover': { backgroundColor: purple[500] },
                        textTransform: 'none',
                        fontSize: '16px',
                      }}
                      variant="contained"
                      onClick={() => setToggleModal(true)}
                    >
                      {ischecked
                        ? `${publicKey.slice(0, 5)}...${publicKey.slice(
                            40,
                            44
                          )}`
                        : 'START HERE'}
                    </Button>

                    <CardChild
                      mode={darkMode}
                      displayPayment={displaypayment}
                      currNetwork={network}
                    >
                      {step === 1 && (
                        <Page1
                          showinput={showinput}
                          setShowInput={setShowInput}
                          splTokenTransfer1={splTokenTransfer1}
                          iscancelled={iscancelled}
                          setIscancelled={setIscancelled}
                          setLastSignature={setLastSignature}
                          transfersolana={transfersolana}
                          setlvlone={setlvlone}
                          setlvltwo={setlvltwo}
                          setthree={setthree}
                          nftTransfer1={nftTransfer1}
                        />
                      )}
                      {step === 2 && (
                        <Page2
                          ischecked={ischecked}
                          setlvlone={setlvlone}
                          setlvltwo={setlvltwo}
                          setthree={setthree}
                        />
                      )}
                      {step === 3 && (
                        <Page3
                          iscancelled={iscancelled}
                          lastSignature={lastSignature}
                          setlvlone={setlvlone}
                          setlvltwo={setlvltwo}
                          setthree={setthree}
                        />
                      )}
                    </CardChild>
                  </Card>
                </div>
                {step === 4 && (
                  <Page4
                    setlvlone={setlvlone}
                    setlvltwo={setlvltwo}
                    setthree={setthree}
                  />
                )}

                {toggleModal && (
                  <SignIn
                    ischecked={ischecked}
                    setIsChecked={setIsChecked}
                    toggleModal={toggleModal}
                    setToggleModal={setToggleModal}
                    network={network}
                  />
                )}
       
                <Landing
                  ischecked2={ischecked2}
                  setIsChecked2={setIsChecked2}
                />
              

                {contactToTeam && (
                  <Contact setcontacttoteam={setContactToTeam} />
                )}
              </Maincontainer>
            </main>
          </div>
        </WalletModalProvider>
      </WalletProvider>
    </ConnectionProvider>
  )
}

export default App
