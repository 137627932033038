import { Program, AnchorProvider, utils } from '@project-serum/anchor'
import { clusterApiUrl, Connection, PublicKey } from '@solana/web3.js'
import { IDL as SubscriptionsIdl, Subscriptions } from '../types/subscriptions'
import { currTime, NetworkTypes1 } from './utils'

const getSubscriptionProgram = (connection: Connection) => {
  const wallet1: any = {}
  const provider = new AnchorProvider(connection, wallet1, {})

  return new Program(
    SubscriptionsIdl as Subscriptions,
    'C64XPGFARHJY6TTo2iVVqCZHojxdyLDgYSsHKPmCfRtk', // devnet
    provider
  )
}

const getUserInfoPDA = async (walletAddr: PublicKey) => {
  const [userInfoPDA] = await PublicKey.findProgramAddress(
    [utils.bytes.utf8.encode('user_info_0'), walletAddr.toBuffer()],
    new PublicKey('C64XPGFARHJY6TTo2iVVqCZHojxdyLDgYSsHKPmCfRtk') // devnet
  )
  return userInfoPDA
}

export const isUserSubscribed = async (network: string, walletAddr: string, isAdmin: boolean) => {
  try {
    if(isAdmin) return true
    if (network !== NetworkTypes1.DEVNET) return false
    const connection = new Connection(clusterApiUrl('devnet'))
    const program = getSubscriptionProgram(connection)
    const userInfoPDA = await getUserInfoPDA(new PublicKey(walletAddr))
    const userInfo = await program.account.userInfo.fetch(userInfoPDA)
    if (userInfo.endTime.toNumber() < currTime()) return false
    return true
  } catch (err) {
    console.log(err)
    return false
  }
}
