import create from 'zustand'
import { NetworkTypes1, TokenTypes1 } from './utils'

interface SplTokenInt {
  decimals: number
  amount: string
  token_mint: string
  name: string
  uri: string
}

interface transferedData {
  tx: string
  destination: string
  amount: number
  flag: boolean
  time: string
}

interface transferedDataNFT {
  tx: string
  destination: string
  mint: string
  flag: boolean
  time: string
}

interface globalState {
  network: string
  setNetwork: (_net: string) => void

  publicKey: string
  setPublicKey: (_key: string) => void
  privateKey: string
  setPrivateKey: (_key: string) => void

  isSubscribed: boolean
  setIsSubscribed: (_sub: boolean) => void
  isAdmin: boolean
  setIsAdmin: (_admin: boolean) => void

  usersTokenList: string[]
  setUsersTokenList: (_tokenL: string[]) => void

  userSplTokenList: SplTokenInt[]
  setUserSplTokenList: (_list: SplTokenInt[]) => void

  tokenAddr: string
  setTokenAddr: (_addr: string) => void

  tokenType: string
  setTokenType: (_type: string) => void

  tokenDecimals: string
  setTokenDecimals: (_dec: string) => void

  recipientData: string
  setRecipientData: (_data: string) => void

  transactionHashListSOL: transferedData[]
  settransactionHashListSOL: (_data: transferedData[]) => void
  transactionHashListSPL: transferedData[]
  settransactionHashListSPL: (_data: transferedData[]) => void
  transactionHashListNFT: transferedDataNFT[]
  settransactionHashListNFT: (_data: transferedDataNFT[]) => void
}

const sampleTokenList: string[] = []

const useStore = create<globalState>((set) => ({
  network: NetworkTypes1.MAINNET,
  setNetwork: (_net) => set((state) => ({ network: _net })),

  publicKey: '',
  setPublicKey: (_key) => set((state) => ({ publicKey: _key })),
  privateKey: '',
  setPrivateKey: (_key) => set((state) => ({ privateKey: _key })),

  isSubscribed: false,
  setIsSubscribed: (_sub) => set((state) => ({ isSubscribed: _sub })),
  isAdmin: false,
  setIsAdmin: (_admin) => set((state) => ({ isAdmin: _admin })),

  usersTokenList: sampleTokenList,
  setUsersTokenList: (_tokenL) => set((state) => ({ usersTokenList: _tokenL })),

  userSplTokenList: [],
  setUserSplTokenList: (_list) => set((state) => ({ userSplTokenList: _list })),

  tokenAddr: '',
  setTokenAddr: (_addr) => set((state) => ({ tokenAddr: _addr })),

  tokenType: TokenTypes1.SPL,
  setTokenType: (_type) => set((state) => ({ tokenType: _type })),

  tokenDecimals: '9',
  setTokenDecimals: (_dec) => set((state) => ({ tokenDecimals: _dec })),

  recipientData: '',
  setRecipientData: (_data) => set((state) => ({ recipientData: _data })),

  transactionHashListSOL: [],
  settransactionHashListSOL: (_data) => {
    set(() => ({ transactionHashListSOL: [..._data] }))
  },

  transactionHashListSPL: [],
  settransactionHashListSPL: (_data) => {
    set(() => ({ transactionHashListSPL: [..._data] }))
  },

  transactionHashListNFT: [],
  settransactionHashListNFT: (_data) => {
    set(() => ({ transactionHashListNFT: [..._data] }))
  },
}))

export default useStore
