import { Navbar, TopNavButton } from '../styling'
import logo from '../../assets/logo.png'
import icon from '../../assets/icon.jpg'
import discord from '../../assets/discord.png'
import { setMainnet, setDevnet } from '../../api/solana-api'
import '../../App.css'
import {
  setDevnetanalytics,
  setMainnetanalytics,
} from '../../api/solana-analytics'
import useStore from '../../backend/store'
import uiStore from '../../backend/uiStore'
import { NetworkTypes1 } from '../../backend/utils'
const Navigation = ({
  setIsChecked,
  ischecked,
  toggleModal,
  setToggleModal,
  setcontacttoteam,
  open,
  click,
  setClick,
}) => {
  const setTokenType = useStore((state) => state.setTokenType)
  const publicKey = useStore((s) => s.publicKey)
  const setPublicKey = useStore((s) => s.setPublicKey)
  const setPrivateKey = useStore((s) => s.setPrivateKey)
  const setStep = uiStore((s) => s.setStep)
  const network = useStore((s) => s.network)
  const setNetwork = useStore((s) => s.setNetwork)
  const darkMode = uiStore((s) => s.darkMode)
  return (
    <Navbar mode={darkMode} network={network} open={open} click={click}>
      <a href="https://solwerx.com/">
        <img src={logo} className="logo" alt="SolWerx" />
      </a>
      <TopNavButton mode={darkMode} click={click}>
        <div
          className="row"
          id="analyticsbtn"
          onClick={() => {
            setStep(4)
          }}
        >
          Verification
        </div>

        <div className="dropdown2">
          <img
            src={icon}
            alt="logo"
            style={{
              height: '21px',
              width: '30px',
              border: '2px solid #993399',
              marginRight: '70px',
            }}
            onClick={() => {
              setClick(!click)
            }}
          />

          {click && (
            <div id="dropdown-content2" className="dropdown-content2">
              <div
                className="row"
                id="analyticsbtn2"
                onClick={() => {
                  setStep(4)
                }}
              >
                Verification
              </div>

              <button
                className="modalswitcher1"
                onClick={() => {
                  setToggleModal(!toggleModal)
                  setIsChecked(false)
                  setPublicKey('')
                  setPrivateKey('')
                  setcontacttoteam(false)
                  setClick(false)
                }}
              >
                {ischecked
                  ? `${publicKey.slice(0, 4)}....${publicKey.slice(40, 44)}`
                  : 'Automate'}
              </button>
            </div>
          )}
        </div>
        <div
          className="dropdown"
          onClick={() => {
            setcontacttoteam(false)
          }}
        >
          <button className="dropbtn">{network}</button>
          <div className="dropdown-content">
            <div
              onClick={() => {
                setMainnet()
                setNetwork(NetworkTypes1.MAINNET)
                setMainnetanalytics()
              }}
            >
              Solana (main-net)
            </div>
            <div
              onClick={() => {
                setDevnet()
                setNetwork(NetworkTypes1.DEVNET)
                setDevnetanalytics()
                setTokenType('splToken')
              }}
            >
              Solana (dev-net)
            </div>
          </div>
        </div>

        <a
          href="https://discord.gg/cyNH6KAGND"
          target="_blank"
          rel="noreferrer"
        >
          <img src={discord} className="discord" alt="solnettools" />
        </a>

      </TopNavButton>
    </Navbar>
  )
}

export default Navigation
